var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: require("@/assets/logo.png") },
                      })
                    : _c("h1", { staticClass: "sidebar-title" }, [
                        _vm._v(_vm._s(_vm.title) + " "),
                      ]),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: require("@/assets/logo.png") },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h1", { staticClass: "sidebar-title" }, [
                    _vm._v(_vm._s(_vm.title) + " "),
                  ]),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }