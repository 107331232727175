import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data
  });
}
export function getLoginCode() {
  return request({
    url: 'code',
    method: 'get'
  });
}
export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function getCurrentUserInfo() {
  return request({
    url: '/current_user/info',
    method: 'get'
  });
}
export function fetchAddressList() {
  return request({
    url: '/all/areas',
    headers: {
      'Content-Type': ''
    },
    method: 'get'
  });
}
export function fetchList(data) {
  return request({
    url: '/persons',
    headers: {
      'Content-Type': ''
    },
    method: 'get',
    params: data
  });
}
export function getPersonResult() {
  return request({
    url: '/result',
    method: 'get'
  });
}
export function deleteUser(id) {
  return request({
    url: "/persons/".concat(id),
    headers: {
      'Content-Type': ''
    },
    method: 'delete'
  });
}
export function fetchList2(data) {
  return request({
    url: '/persons_v2',
    headers: {
      'Content-Type': ''
    },
    method: 'get',
    params: data
  });
}
export function deleteUser2(id) {
  return request({
    url: "/persons_v2/".concat(id),
    headers: {
      'Content-Type': ''
    },
    method: 'delete'
  });
}
export function exportUser(data) {
  return request({
    url: '/to_excel',
    headers: {
      'Content-Type': ''
    },
    method: 'get',
    params: data
  });
}
export function exportUser2(data) {
  return request({
    url: '/v2_to_excel',
    headers: {
      'Content-Type': ''
    },
    method: 'get',
    params: data
  });
}