import Layout from '@/layout';
var mechanismUserRouter = {
  path: '/mechanismUser',
  component: Layout,
  redirect: '/mechanismUser/list',
  name: 'Member',
  meta: {
    title: '机构人员',
    icon: 'mechanismUser'
  },
  alwaysShow: true,
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/mechanismUser/list');
    },
    name: 'MemberList',
    meta: {
      title: '人员管理'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/mechanismUser/edit');
    },
    name: 'MemberEdit',
    hidden: true
  }, {
    path: 'create',
    component: function component() {
      return import('@/views/mechanismUser/create');
    },
    name: 'MemberAdd',
    hidden: true
  }]
};
export default mechanismUserRouter;