import Layout from '@/layout';
var questionnaireRouter = {
  path: '/dashbord',
  component: Layout,
  redirect: '/dashbord/list',
  name: 'dashbord',
  meta: {
    title: '首页',
    icon: 'dashbord'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/questionnaire/index');
    },
    name: 'dashbordList',
    meta: {
      title: '首页',
      icon: 'questionnaire'
    }
  }]
};
export default questionnaireRouter;