import Layout from '@/layout';
var mechanismRouter = {
  path: '/mechanism',
  component: Layout,
  redirect: '/mechanism/list',
  name: 'Organization',
  meta: {
    title: '机构管理',
    icon: 'mechanism'
  },
  alwaysShow: true,
  children: [{
    path: 'area',
    component: function component() {
      return import('@/views/mechanism/area');
    },
    name: 'AreaList',
    meta: {
      title: '地区管理'
    }
  }, {
    path: 'address',
    component: function component() {
      return import('@/views/mechanism/address');
    },
    name: 'StreetList',
    meta: {
      title: '街道管理'
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/mechanism/list');
    },
    name: 'OrganizationList',
    meta: {
      title: '机构列表'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/mechanism/edit');
    },
    name: 'OrganizationEdit',
    hidden: true
  }, {
    path: 'create',
    component: function component() {
      return import('@/views/mechanism/create');
    },
    name: 'OrganizationCreate',
    hidden: true
  }
  // {
  //   path: 'index',
  //   component: () => import('@/views/mechanism/index'),
  //   name: 'OrganizationHome',
  //   meta: { title: '机构首页' }
  // },
  ]
};

export default mechanismRouter;