import request from '@/utils/request';
export function fetchAdminUserList(data) {
  return request({
    url: '/users',
    headers: {
      'Content-Type': ''
    },
    method: 'get',
    params: data
  });
}
export function createAdminUser(data) {
  return request({
    url: '/users',
    method: 'post',
    params: data
  });
}
export function deleteAdminUser(id) {
  return request({
    url: "/users/".concat(id),
    method: 'delete'
  });
}
export function getUserRolesApi(id) {
  return request({
    url: "/users/".concat(id, "/roles"),
    method: 'get'
  });
}
export function updateUserRolesApi(id, data) {
  return request({
    url: "/users/".concat(id, "/roles"),
    method: 'post',
    data: data
  });
}
export function fetchVideoList(data) {
  return request({
    url: '/pad/files',
    headers: {
      'Content-Type': ''
    },
    method: 'get',
    params: data
  });
}
export function deleteVideo(id) {
  return request({
    url: "/pad/files/".concat(id),
    method: 'delete'
  });
}
export function updateVideo(id, data) {
  return request({
    url: "/pad/files/".concat(id),
    method: 'put',
    data: data
  });
}
export function addVideo(data) {
  return request({
    url: "/pad/files",
    method: 'post',
    data: data
  });
}

// 修改密码
export function updatePsd(data) {
  return request({
    url: "/login",
    method: 'patch',
    data: data
  });
}