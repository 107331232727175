import Layout from '@/layout';
var systemRouter = {
  path: '/system',
  component: Layout,
  redirect: '/system/list',
  name: 'System',
  meta: {
    title: '系统设置',
    icon: 'system'
  },
  alwaysShow: true,
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/system/user');
    },
    name: 'UserList',
    meta: {
      title: '用户管理'
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/system/role');
    },
    name: 'RoleList',
    meta: {
      title: '角色管理'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/mechanism/edit');
    },
    name: 'UserEdit',
    hidden: true
  }, {
    path: 'create',
    component: function component() {
      return import('@/views/mechanism/create');
    },
    hidden: true,
    name: 'UserCreate',
    meta: {
      title: '新建用户'
    }
  }]
};
export default systemRouter;