var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("span", [_vm._v(_vm._s(this.userData["name"] || ""))]),
                _vm._v(" "),
                _c("span", { staticClass: "split-icon" }, [_vm._v("|")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(this.userData["last_login"]))]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: require("@/assets/logo2.png") },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm.userData.id != 1
                    ? _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: {
                              click: function () {
                                return (_vm.isVisible = true)
                              },
                            },
                          },
                          [_vm._v("修改密码")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("退出")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-view",
          attrs: { title: "修改密码", visible: _vm.isVisible },
          on: {
            "update:visible": function ($event) {
              _vm.isVisible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "old_password", label: "旧密码：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入旧密码" },
                    model: {
                      value: _vm.form.old_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "old_password", $$v)
                      },
                      expression: "form.old_password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "new_password", label: "新密码：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入8-18位密码" },
                    model: {
                      value: _vm.form.new_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "new_password", $$v)
                      },
                      expression: "form.new_password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "password_again", label: "确认密码：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入8-18位新密码" },
                    model: {
                      value: _vm.form.password_again,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password_again", $$v)
                      },
                      expression: "form.password_again",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }