import Layout from '@/layout';
var userRouter = {
  path: '/user',
  component: Layout,
  redirect: '/user/list',
  name: 'Aged',
  meta: {
    title: '评估对象',
    icon: 'user'
  },
  alwaysShow: true,
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/user/list');
    },
    name: 'AgedList',
    meta: {
      title: '评估对象管理V1.0'
    }
  }, {
    path: 'list2',
    component: function component() {
      return import('@/views/user/list2');
    },
    name: 'AgedList2',
    meta: {
      title: '评估对象管理V2.0'
    }
  }]
};
export default userRouter;